<script lang="ts" setup>
import { getCurrentTime } from '@qcwp/utils'
import { AD_POSITION } from '~~/src/server/modules/ad/types'
import { getAdComponentData } from '~~/src/store'

const { isDesktop } = useDevice()
const adKey = getAdKey()
const route = useRoute()
const { adData: A9 } = getAdComponentData(unref(adKey), AD_POSITION.A9)
const { adData: A10 } = getAdComponentData(unref(adKey), AD_POSITION.A10)
</script>

<template>
  <layout-nav v-if="isDesktop" />
  <ClientOnly>
    <div v-if="isDesktop && A9" class="fixed w-40 bottom-10 left-[calc((100vw-68.75rem)/2-11.5rem)] hidden md:block">
      <LazyAdCommonA1 :ad-key="adKey" :position="AD_POSITION.A9" />
    </div>
    <div v-if="isDesktop && A10" class="fixed w-40 top-30 right-[calc((100vw-68.75rem)/2-11.5rem)] hidden md:block">
      <LazyAdCommonA1 :ad-key="adKey" :position="AD_POSITION.A10" />
    </div>
    <div v-if="!['live-articleId'].includes(route.name as string) || isDesktop" class="fixed right-5 bottom-10 grid grid-cols-1 gap-3 z-99" lt-md="bottom-20 right-3">
      <LazyUtilsUp />

      <slot name="refresh" />
      <LazyUtilsFeedback />
    </div>
  </ClientOnly>
  <slot />
  <template v-if="route.name === 'index'">
    <div class="bg-slate-8 text-white pb-sm lt-md:mt-sm">
      <div class="layout-default-container">
        <div class="flex items-center justify-center text-center py-xl">
          <nuxt-link to="/docs/about">
            关于我们
          </nuxt-link>
          <span class="border-l-2 border-slate-6 h-2.5 inline-block pr-sm ml-sm" />
          <nuxt-link to="/help/link">
            友情链接
          </nuxt-link>
          <span class="border-l-2 border-slate-6 h-2.5 inline-block pr-sm ml-sm" />
          <nuxt-link to="/docs/statement">
            免责声明
          </nuxt-link>
          <span class="border-l-2 border-slate-6 h-2.5 inline-block pr-sm ml-sm" />
          <nuxt-link to="/docs/ad">
            广告服务
          </nuxt-link>
        </div>
        <div class="flex items-center justify-center text-3 flex-wrap">
          <span font-mono>Copyright © 2008 - {{ getCurrentTime('YYYY') }}</span>
          <span>汽车网评</span>
          <span class="border-l-2 border-slate-6 h-2.5 inline-block pr-sm ml-sm" />
          <a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank" hover:text-blue-5>
            粤ICP备12081151号
          </a>
          <span class="border-l-2 border-slate-6 h-2.5 inline-block pr-sm ml-sm lt-md:hidden" />
          <span>
            广州车轱辘网络科技有限公司版权所有
          </span>
        </div>
      </div>
    </div>
  </template>
</template>
